import React from "react";
import "./testimonials.css";
import Avtr1 from "../../assets/avatar1.jpg";
import Avtr2 from "../../assets/avatar2.jpg";
import Avtr3 from "../../assets/avatar3.jpg";
import Avtr4 from "../../assets/avatar4.jpg";
// import Swiper core and required modules
import {Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const Testimonials = () => {
  const data = [
    {
      avater: Avtr1,
      name: "Zoe",
      review:
        "I was extremely impressed with drean's ability to understand our business needs and deliver a custom solution that exceeded our expectations. I loved his team's attention to detail and willingness to go above and beyond. This made the process seamless and easy.",
    },
    {
      avater: Avtr2,
      name: "John",
      review:
        "Drean's creativity and passion for storytelling really shone through in the final product. Together with his team, they were able to take our ideas and turn them into something truly special.",
    },
    {
      avater: Avtr3,
      name: "Nick",
      review:
        "Drean was professional, responsive, and extremely knowledgeable. His team helped us navigate the complex world of software development and delivered a top-quality product.",
    },
    {
      avater: Avtr4,
      name: "Aoleon",
      review:
        "Drean was a true collaborator, always open to feedback and willing to make changes to ensure the final product was exactly what we were looking for.",
    }
  ];

  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonials__container"
            modules={[Pagination]}
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}
      >
        {data.map(({avater,name,review},index)=>{
          return(
            <SwiperSlide key={index} className="testimonial">
            <div className="client__avatar">
              <img src={avater} alt="client image" />
            </div>
            <h5 className="client__name">{name}</h5>
            <small className="client__review">{review}</small>
          </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
