import React from 'react'
import{FaGithub} from 'react-icons/fa'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://github.com/d-r-e-a-n" target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials