import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";

const Services = () => {
  return (
    <section id="services">
      <h5>What I offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Visual Production</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Script development.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Pre-Production.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Production.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Post-production.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Distribution and marketing.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Software Engineering</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Custom software development.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                 Web and mobile development.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                 Cloud computing.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Quality assurance and testing.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Maintenance and support.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Data analysis and visualization.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Cybersecurity.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}
        <article className="service">
          <div className="service__head">
            <h3>Visual Marketing</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                 Corporate Video Production.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Animated explainer videos.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Live-action video production.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Post-production services.
              </p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                Distribution and Promotion.
              </p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  );
};

export default Services;
